import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { AuthenticationService } from 'src/app/services/authguard/authentication.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  source_from: any
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private authenticationService: AuthenticationService,
    private router: Router,
    public alertController: AlertController,
    public auth: AuthenticationService,
  ) {

      this.initializeApp();
      
  }

  initializeApp() {    
    this.platform.ready().then(() => {
      this.splashScreen.hide();


      this.authenticationService.authenticationState.subscribe(state => {
        let url = window.localStorage.getItem('pageUrl');
        if (state) {
          // (url)? this.router.navigate([url]):this.router.navigate(['/rootTabs']);
          // this.router.navigate(['/onboard']); 
          // this.router.navigate(['/tabs']); 
        } else {
          // onboard
          // this.router.navigate(['/onboard']);
          // /tabs/dashboard
        }
      });

    });

  }



}
