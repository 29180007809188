import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthguardService } from './services/authguard/authguard.service';

const routes: Routes = [
  {
    path: '',
    // redirectTo: 'onboard',
    // redirectTo: 'user-profile/feedback',
    pathMatch: 'full',
    loadChildren: () => import('./pages/onbording/onboard/onboard.module').then( m => m.OnboardPageModule)
  },
  {
    path: 'onboard',
    loadChildren: () => import('./pages/onbording/onboard/onboard.module').then( m => m.OnboardPageModule)
  },
  {
    path: 'login',
    // canActivate:[AuthguardService],
    loadChildren: () => import('./pages/onbording/login/login.module').then( m => m.LoginPageModule)
  },
  
  {
    path: 'password',
    // canActivate:[AuthguardService],
    loadChildren: () => import('./pages/onbording/password/password.module').then( m => m.PasswordPageModule)
  },
  {
    path: 'terms-and-conditions',
    // canActivate:[AuthguardService],
    loadChildren: () => import('./pages/onbording/tearms-and-condition/tearms-and-condition.module').then( m => m.TearmsAndConditionPageModule)
  },
  {
    path: 'dashboard',
    //  canActivate:[AuthguardService],
    loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/onbording/profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'landing',
    loadChildren: () => import('./pages/onbording/landing/landing.module').then( m => m.LandingPageModule)
  },
  {
    path: 'key-contact',
    // canActivate:[AuthguardService],
    loadChildren: () => import('./pages/onbording/key-contact/key-contact.module').then( m => m.KeyContactPageModule)
  },
  {
    path: 'bank-details',
    // canActivate:[AuthguardService],
    loadChildren: () => import('./pages/onbording/bank-details/bank-details.module').then( m => m.BankDetailsPageModule)
  },
  {
    path: 'validation-id-model',
    loadChildren: () => import('./pages/onbording/validation-id-model/validation-id-model.module').then( m => m.ValidationIdModelPageModule)
  },
  {
    path: 'conformation-modal',
    loadChildren: () => import('./pages/conformation-modal/conformation-modal.module').then( m => m.ConformationModalPageModule)
  },
  {
    path: 'registration/:id',
    // canActivate:[AuthguardService],
    loadChildren: () => import('./pages/onbording/registration/registration.module').then( m => m.RegistrationPageModule)
  },
  {
    path: 'success-pop-up',
    loadChildren: () => import('./pages/onbording/success-pop-up/success-pop-up.module').then( m => m.SuccessPopUpPageModule)
  },
  {
    path: 'user-profile',
    loadChildren: () => import('./pages/user-profile/user-profile.module').then(m => m.UserProfilePageModule),

  },


  




];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
