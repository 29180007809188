import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {

  constructor(
    private router :Router
  ) { }

  ngOnInit() {}

  terms(){
    this.router.navigateByUrl("/terms-and-conditions")
  }
  contact(){
    this.router.navigateByUrl("/user-profile/contact-us")
  }
  policy(){
    this.router.navigateByUrl("/user-profile/privacy")
  }
  faq(){
    this.router.navigateByUrl("/user-profile/faq")

  }
}
